var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "md-layout" }, [
      _c(
        "div",
        { staticClass: "md-layout-item md-size-80 mx-auto" },
        [
          _c(
            "md-card",
            { staticClass: "md-card-calendar" },
            [
              _c("md-card-content", [
                _c("div", { attrs: { id: "fullCalendar" } }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header text-center" }, [
      _c("h3", { staticClass: "title" }, [_vm._v("FullCalendar.io")]),
      _c("p", { staticClass: "category" }, [
        _vm._v("Handcrafted by our friends from "),
        _c(
          "a",
          { attrs: { target: "_blank", href: "https://fullcalendar.io/" } },
          [_vm._v("FullCalendar.io")]
        ),
        _vm._v(". Please checkout the "),
        _c(
          "a",
          { attrs: { href: "https://fullcalendar.io/docs", target: "_blank" } },
          [_vm._v("full documentation")]
        ),
        _vm._v(". "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }